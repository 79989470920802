<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="./img/顶部背景.png" alt="" />
    </div>

    <div class="bottomCss">
      <div id="chuneng" class="chanpingCss">
        <div class="xinwen">
          <div class="title">
            烽火富华荣获“2023中国充换电行业十大竞争力品牌”
          </div>
          <div class="time">2023/06/09</div>
          <img class="imgCss" src="./img/喜报.jpg" alt="" />
          <div class="zhengwen">
            6月7日，由中国充电桩网、充换电百人会主办的“2023上海国际充电桩及换电技术设备展览会（简称CPSE）”在上海汽车会展中心盛大开展。超2.5万平米展馆、超500家展商、超2万人以上专业观众共同组成了声势浩大的展会阵容。在展会同期举办的“2023中国充换电行业十大品牌评选活动”上，烽火富华荣获“2023中国充换电行业十大竞争力品牌”。
          </div>
          <div style="text-align: center;margin-top: 42px;">
            <img class="imgCss" style="width: 40%;" src="./img/喜报2.jpg" alt="" />
          <img class="imgCss"  style="width: 40%;" src="./img/喜报3.jpg" alt="" />
          </div>
          <img class="imgCss"  style="width: 45%;" src="./img/喜报4.jpg" alt="" />
          <div class="xioabioti">
            评选背景
          </div>
          <div class="zhengwen">
            2023中国充换电行业十大品牌评选活动，由中国充电桩网、充换电百人会主办，以公平、公正、公开的原则，全力打造中国最具影响力和号召力的品牌评选活动。作为中国权威、专业的充换电品牌评选活动，本次活动以国际化的视角呈现和挖掘百余家充换电领域最具影响力的品牌企业，网络投票累计约四十万人参与，在此基础上还邀请了行业内数十位专家共同参与评审。本届十大品牌是以主营收入为依据，结合企业发展规模、经济效益、技术创新、市场拓展、社会贡献等几个方面综合认定的。
          </div>
          <div class="zhengwen">
            武汉烽火富华电气有限责任公司作为优质企业之一在海选中脱颖而出，成功入围2023中国充换电行业十大影响力品牌和2023中国充换电行业十大竞争力品牌，在网络投票环境人气遥遥领先并最终当选为2023中国充换电行业十大竞争力品牌。
          </div>
          <img class="imgCss"  style="width: 40%;" src="./img/喜报5.jpg" alt="" />
          <div class="xioabioti">
            入围理由
          </div>
          <div class="zhengwen">
            武汉烽火富华电气有限责任公司是一家专注于储能与新能源汽车充电桩的研发、生产和销售以及整体系统解决方案的国家高新技术企业、武汉东湖高新技术开发区“3551人才”企业、湖北省“专精特新”小巨人企业。位于武汉光谷国家高新技术开发区，核心研发人员占比近50%。
          </div>
          <div class="zhengwen">
            烽火富华充电桩系列产品功率范围覆盖7-800KW，具有超宽电压适配、恒功率高效率不间断输出、600KW液冷终端等技术亮点，储能及充电桩产品覆盖国标、欧、美、日4大国际通用标准。公司深耕智慧能源电力变换及储能领域，致力于将信息通信技术与电力电子技术融合，是湖北省领先的智慧能源领域产品与解决方案提供商。
          </div>
          <div class="xioabioti">
            获奖感言
          </div>
          <div class="zhengwen">
            对于烽火富华来说，荣获“2023中国充换电行业十大竞争力品牌”，既是一份肯定和荣誉，又是一份期望和责任。近年来，我司坚持以人才培养支撑技术创新，以开放合作支撑市场开拓，迅速成长为湖北市场领军企业，初步形成全国竞争力；下一步，公司要加大海外市场开拓力度，加快形成全球竞争力。
          </div>
          <div class="zhengwen">
            路虽难，行则将至；事虽难，做则必成。公司的发展进步从来都不是在风平浪静中取得的，而是凭着“敢为天下先”的拼搏，靠着“逢山开路，遇水架桥”的干劲，在齐心协力的奋斗中取得的，我们将继续增强生存力、竞争力、发展力、持续力，为实现全球“双碳”目标贡献一份力量。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 0, //选中项
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.bottomCss {
  width: 100%;
  height: 100%;
  padding-bottom: 88px;
  background: #f2f5f8;
}
.chanpingCss {
  padding-top: 72px;
  display: flex;
  /* text-align: center; */
  flex-direction: column;
  align-items: center;
}
.xinwen {
  padding: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1314px;
  /* height: 360px; */
  background: #ffffff;
  margin-bottom: 16px;
  /* margin-top: 72px; */
}
.title {
  font-size: 38px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  
}
.time {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 16px;
  margin-bottom: 42px;
}
.imgCss {
  width: 800px;
  /* height: 600px; */
}
.zhengwen {
  text-indent: 2em;
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 38px;
  margin-top: 36px;
}
.xioabioti{
  text-indent: 2em;
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 38px;
  margin-top: 36px;
  width: 100%;
    float: left;
}
</style>